menu > button{
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    cursor: pointer;
    text-align: left;
    padding: 30px;
    font-size: 3em;

}

menu > button:hover{
    background: rgb(136,136,136);
    background: -moz-linear-gradient(90deg, rgba(136,136,136,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(136,136,136,1) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(90deg, rgba(136,136,136,1) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#888888",endColorstr="#000000",GradientType=1);
}



menu{
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.bsepa {
    background: rgb(255,255,255);
    background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=1);
    width: 500px;
    height: 2px;
}



menu > button:disabled {
    background: rgb(168,2,0);
    background: -moz-linear-gradient(90deg, rgba(168,2,0,0.5550814075630253) 0%, rgba(16,16,16,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(168,2,0,0.5550814075630253) 0%, rgba(16,16,16,1) 100%);
    background: linear-gradient(90deg, rgba(168,2,0,0.5550814075630253) 0%, rgba(16,16,16,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a80200",endColorstr="#101010",GradientType=1);
    opacity: 0.5;
    cursor: not-allowed;
}


