@font-face {
    font-family: "Jacques Francois";
    src: url('./fonts/JacquesFrancois-Regular.otf') format('opentype');
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Jacques Francois";
}


body{
    background-color: rgb(0, 0, 0);
    color: aliceblue;
}

