
.GameSquare{
    width: 500px;
    height: 500px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


    background-color: rgb(255, 255, 255);


}

.GameLife{
    font-size: 1.5em;
    position: fixed;
    top: 17%;
    right: 20%;
}
.heart{
    width: 30px;
}

.ScoreNumber{
    font-size: 1.5em;
    text-align: center;

    position: fixed;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
}

.Board{
    font-size: 1.5em;
    // border: solid red 1px;

    width: fit-content;

    display: flex;
    flex-direction: column;
    gap: 50px;

    position: fixed;
    top: 17%;
    left: 18%;
}


.gameOverContainer{
    width: 100%;
    height: 100%;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.gameOverVideo{
    width: 100%;
    height: 100%;
}

.statsOverlay{
    position: fixed;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 99999;
}



.statsOverlay > button{
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    cursor: pointer;
    text-align: left;
    padding: 30px;
    font-size: 3em;
}

.statsOverlay > button:hover{
    text-decoration: underline;
}

.stats{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 1.2em;
    gap: 50px;

    position: fixed;
    top: -50px;
    left: 50%;
    transform: translate(-50%, -50%);

    white-space: nowrap;
    text-align: center;

    span{
        font-weight: bold;
    }
}



